import { api } from '@http/index';
import { DocumentKeys } from '@pages/main/visits/components/visit/compoments/postal-table/components/documentTypes';
import { AxiosResponse } from 'axios';

const getAddressLabel = (id: number | string, type: DocumentKeys): Promise<AxiosResponse<ArrayBuffer>> =>
	api.get(`label/${id}${type === 'label' ? '' : '/' + type}`, { responseType: 'arraybuffer' });

const getAddressMarketLabel = (number: string | number, type: DocumentKeys): Promise<AxiosResponse<ArrayBuffer>> =>
	api.post(`market-label/${type}`, { SerialNumber: [{ SerialNumber: number }] }, { responseType: 'arraybuffer' });

const getJuristicGroupAcceptance = (postalIds: number[], isShow?: boolean): Promise<AxiosResponse<ArrayBuffer>> =>
	api.post(
		'label/get-acceptance-for-legal-entities',
		{ postalItemId: postalIds, show: isShow || false },
		{ responseType: 'arraybuffer' }
	);

const getIndividualsGroupAcceptance = (postalIds: number[], isShow?: boolean): Promise<AxiosResponse<ArrayBuffer>> =>
	api.post(
		'label/get-acceptance-for-individuals',
		{ postalItemId: postalIds, show: isShow || false },
		{ responseType: 'arraybuffer' }
	);

const getGroupCashOnDelivery = (postalIds: number[]): Promise<AxiosResponse<ArrayBuffer>> =>
	api.post('label/get-group-issuing-cash-on-delivery', { postalItemId: postalIds }, { responseType: 'arraybuffer' });

const getIndividualsGroupIssuance = (postalIds: number[], isShow?: boolean): Promise<AxiosResponse<ArrayBuffer>> =>
	api.post(
		'label/get-issuance-for-individuals',
		{ postalItemId: postalIds, show: isShow || false },
		{ responseType: 'arraybuffer' }
	);

const getRefundAct = (id: number): Promise<AxiosResponse<ArrayBuffer>> =>
	api.get(`label/refund-act/${id}`, { responseType: 'arraybuffer' });

const getRefundReceipt = (ids: number[]): Promise<AxiosResponse<ArrayBuffer>> =>
	api.post(`label/refund`, { postal_item_id: ids }, { responseType: 'arraybuffer' });

const getPaymentAct = (id: number): Promise<AxiosResponse<ArrayBuffer>> =>
	api.get(`label/payment-act/${id}`, { responseType: 'arraybuffer' });

const getClaimAct = (id: number): Promise<AxiosResponse<ArrayBuffer>> =>
	api.get(`label/claim-act/${id}`, { responseType: 'arraybuffer' });

const getAutopsyAct = (id: number): Promise<AxiosResponse<ArrayBuffer>> =>
	api.get(`label/autopsy-act/${id}`, { responseType: 'arraybuffer' });

const getDefectiveConditionAct = (id: number): Promise<AxiosResponse<ArrayBuffer>> =>
	api.get(`label/defective-condition-act/${id}`, { responseType: 'arraybuffer' });

const getInventoryAct = (id: number): Promise<AxiosResponse<ArrayBuffer>> =>
	api.get(`label/${id}/inventory`, { responseType: 'arraybuffer' });

const getThermalAct = (id: number): Promise<AxiosResponse<ArrayBuffer>> =>
	api.get(`label/thermal-label/${id}`, { responseType: 'arraybuffer' });

const getBreakInfo = (breakId: number): Promise<AxiosResponse<ArrayBuffer>> =>
	api.get(`break/${breakId}/info`, { responseType: 'arraybuffer' });

const getRoutingListsAct = (id: number): Promise<AxiosResponse<ArrayBuffer>> =>
	api.get(`label/routing-lists/${id}`, { responseType: 'arraybuffer' });

const getRoutingSurplusAct = (id: number): Promise<AxiosResponse<ArrayBuffer>> =>
	api.get(`label/routing/surplus/${id}`, { responseType: 'arraybuffer' });

const getStaticSpaceQr = (id: number): Promise<AxiosResponse<ArrayBuffer>> =>
	api.get(`/label/static-place/qr/${id}`, { responseType: 'arraybuffer' });

export const LabelService = {
	getAddressLabel,
	getAddressMarketLabel,
	getJuristicGroupAcceptance,
	getIndividualsGroupAcceptance,
	getGroupCashOnDelivery,
	getIndividualsGroupIssuance,
	getRefundAct,
	getPaymentAct,
	getClaimAct,
	getAutopsyAct,
	getDefectiveConditionAct,
	getRefundReceipt,
	getInventoryAct,
	getThermalAct,
	getBreakInfo,
	getRoutingListsAct,
	getRoutingSurplusAct,
	getStaticSpaceQr,
};
