import AntIcon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import cn from 'classnames';
import { FC } from 'react';

import styles from './index.module.scss';
import { ReactComponent as AccountIcon } from './svg/account_icon.svg';
import { ReactComponent as LeftArrow } from './svg/arrow_left.svg';
import { ReactComponent as ArrowCircleDown } from './svg/arrow-circle-down.svg';
import { ReactComponent as ArrowUp } from './svg/arrow-up.svg';
import { ReactComponent as Card } from './svg/card.svg';
import { ReactComponent as CheckCircle } from './svg/Check circle.svg';
import { ReactComponent as CheckCircleFilled } from './svg/check-circle-filled.svg';
import { ReactComponent as CheckCircleOrange } from './svg/check-circle-orange.svg';
import { ReactComponent as Close } from './svg/Close.svg';
import { ReactComponent as Compensations } from './svg/compensations.svg';
import { ReactComponent as Delete } from './svg/Delete.svg';
import { ReactComponent as DeliveryParcel } from './svg/delivery_parcel.svg';
import { ReactComponent as Edit } from './svg/Edit.svg';
import { ReactComponent as NoTasks } from './svg/empty-img-gray.svg';
import { ReactComponent as Evez } from './svg/evez.svg';
import { ReactComponent as EvezWhite } from './svg/evez-white.svg';
import { ReactComponent as Exclamation } from './svg/exclamation.svg';
import { ReactComponent as ExclamationCircle } from './svg/exclamation-circle.svg';
import { ReactComponent as Eye } from './svg/Eye.svg';
import { ReactComponent as FileCopy } from './svg/File_copy.svg';
import { ReactComponent as Filter } from './svg/filter.svg';
import { ReactComponent as IdentificationIconNav } from './svg/Identification_icon.svg';
import { ReactComponent as Info } from './svg/Info outline.svg';
import { ReactComponent as LibraryBooks } from './svg/Library_books.svg';
import { ReactComponent as LocalShipping } from './svg/Local_shipping.svg';
import { ReactComponent as Lock } from './svg/lock.svg';
import { ReactComponent as Mail } from './svg/Mail.svg';
import { ReactComponent as MailGray } from './svg/mail-gray.svg';
import { ReactComponent as MailWhite } from './svg/mail-white.svg';
import { ReactComponent as Minus } from './svg/minus.svg';
import { ReactComponent as Moon } from './svg/moon.svg';
import { ReactComponent as Moving } from './svg/moving.svg';
import { ReactComponent as Pause } from './svg/pause.svg';
import { ReactComponent as Payments } from './svg/Payments.svg';
import { ReactComponent as PaymentsBlack } from './svg/payments-black.svg';
import { ReactComponent as PaymentsWhite } from './svg/payments-white.svg';
import { ReactComponent as People } from './svg/People.svg';
import { ReactComponent as Photo } from './svg/Photo camera.svg';
import { ReactComponent as PlaylistAddCheck } from './svg/Playlist_add_check.svg';
import { ReactComponent as Plus } from './svg/plus.svg';
import { ReactComponent as Print } from './svg/Print.svg';
import { ReactComponent as QrIcon } from './svg/qr-icon.svg';
import { ReactComponent as ReceiptLong } from './svg/Receipt_long.svg';
import { ReactComponent as Replay } from './svg/replay.svg';
import { ReactComponent as ReplayEmall } from './svg/replay-emall.svg';
import { ReactComponent as ReplayEmallWhite } from './svg/replay-emall-white.svg';
import { ReactComponent as ReplayWhite } from './svg/replay-white.svg';
import { ReactComponent as SearchPlus } from './svg/search-plus.svg';
import { ReactComponent as Settings } from './svg/settings.svg';
import { ReactComponent as Sheet } from './svg/sheet.svg';
import { ReactComponent as ShoppingBag } from './svg/shopping-bag.svg';
import { ReactComponent as ShoppingBagBlack } from './svg/shopping-bag-black.svg';
import { ReactComponent as ShoppingBagWhite } from './svg/shopping-bag-white.svg';
import { ReactComponent as Arrow } from './svg/simple_arrow.svg';
import { ReactComponent as Spin } from './svg/spin.svg';
import { ReactComponent as Spinner } from './svg/spinner.svg';
import { ReactComponent as Sun } from './svg/sun.svg';
import { ReactComponent as TempCargoSpace } from './svg/temp-cargo-space.svg';
import { ReactComponent as Truck } from './svg/truck.svg';
import { ReactComponent as Unlock } from './svg/unlock.svg';
import { ReactComponent as DownLoad } from './svg/Vertical align bottom.svg';
import { ReactComponent as WinClose } from './svg/win-close.svg';

export const images = {
	Arrow,
	ArrowCircleDown,
	CheckCircle,
	CheckCircleFilled,
	Close,
	Delete,
	DownLoad,
	Edit,
	Exclamation,
	Eye,
	FileCopy,
	Info,
	LeftArrow,
	LibraryBooks,
	LocalShipping,
	Mail,
	MailGray,
	Minus,
	Moon,
	NoTasks,
	Pause,
	Payments,
	People,
	Photo,
	PlaylistAddCheck,
	Plus,
	Print,
	ReceiptLong,
	Settings,
	Spinner,
	Sheet,
	ShoppingBag,
	Sun,
	WinClose,
	Compensations,
	IdentificationIconNav,
	AccountIcon,
	Filter,
	Card,
	QrIcon,
	Evez,
	SearchPlus,
	Spin,
	Replay,
	ReplayEmall,
	ArrowUp,
	DeliveryParcel,
	PaymentsBlack,
	ShoppingBagBlack,
	Lock,
	Unlock,
	Moving,
	Truck,
	CheckCircleOrange,
	ExclamationCircle,
	EvezWhite,
	MailWhite,
	ReplayWhite,
	PaymentsWhite,
	ReplayEmallWhite,
	ShoppingBagWhite,
	TempCargoSpace,
};

export type ImageName = keyof typeof images;
type IconProps = Partial<CustomIconComponentProps> & {
	imageName: ImageName;
	color?: string;
	fontSize?: number;
	rotate?: number;
	stroke?: string;
	disabled?: boolean;
	hasMark?: boolean;
};

const Icon: FC<IconProps> = ({ color, fontSize, stroke, imageName, className, hasMark, ...props }) => {
	return (
		<AntIcon
			className={cn(className, styles.icon, { [styles.icon__mark]: hasMark })}
			component={images[imageName]}
			{...props}
			style={{ ...props.style, color: color || 'inherit', stroke, fontSize: fontSize ?? 20 }}
		/>
	);
};

export default Icon;
