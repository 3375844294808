/* eslint-disable @typescript-eslint/no-empty-interface */
import { LabelService } from '@services/label';
import LogService from '@services/log';
import { PrintService } from '@services/print';
import { ReportsService } from '@services/reports';
import StoreService from '@services/store';
import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { toJS } from 'mobx';
import { applySnapshot, flow, getParent, SnapshotIn, types } from 'mobx-state-tree';
import { addZero } from 'utils/addZero';
import { deleteFromGlobalBlob, saveGlobalBlob } from 'utils/globalObject';

import { RootStore } from '.';

const { model, maybeNull, string, literal, union, number, array, boolean, optional } = types;

const Printers = model('Printers', { termo: maybeNull(string), others: maybeNull(string) }).actions((self) => ({
	setTermo(value: string) {
		self.termo = value;
	},
	setOthers(value: string) {
		self.others = value;
	},
}));

const PrintTask = model('PdfModalData', {
	type: union(
		literal('label'),
		literal('sales-receipt'),
		literal('return-application'),
		// literal('software-receipt'),
		// literal('acceptance-for-individuals'),
		literal('thermal'),
		// literal('receipt-issuence-np'),
		// literal('receipt-upon-acceptance-for-legal-entities'),
		literal('np-acceptance-receipt'),
		literal('get-acceptance-for-legal-entities'),
		literal('get-acceptance-for-individuals'),
		literal('get-group-issuing-cash-on-delivery'),
		literal('get-issuance-for-individuals'),
		literal('refund-act'),
		literal('refund-receipt'),
		literal('payment-act'),
		literal('cash-register-report-pdf'),
		literal('cash-register-report-pdf-employees-general'),
		literal('cash-register-report-pdf-employees-full'),
		literal('autopsy-act'),
		literal('defective-condition-act'),
		literal('claim-act'),
		literal('qr'),
		literal('inventory-act'),
		literal('break'),
		literal('routing-lists'),
		literal('routing-surplus'),
		literal('static-space-qr')
	),
	number: maybeNull(string),
	id: maybeNull(number),
	ids: array(number),
	copies: '1',
	date: maybeNull(string),
}).views((self) => ({
	get isMarketLabel() {
		return self.type && ['receipt-issuence-np', 'np-acceptance-receipt'].includes(self.type);
	},
	get taskId() {
		return String(this.isMarketLabel ? self.number : self.id);
	},
}));

const Print = model('Print', {
	printers: Printers,
	tmpPrinters: Printers,
	availablePrinters: array(string),
	printPreview: maybeNull(PrintTask),
	pdfPreviewDataId: maybeNull(string),
	printerSettingsOpen: optional(boolean, false),
	delayedTasks: array(PrintTask),
	errorThermal: false,
	errorOthers: false,
})
	.actions((self) => {
		const root = getParent<typeof RootStore>(self);
		const closeLoader = (): void => root.setLoading(false);

		return {
			savePrinters() {
				localStorage.setItem('printers', JSON.stringify(toJS(self.printers)));
				self.printerSettingsOpen = false;

				if (self.delayedTasks.length && !(self.errorOthers || self.errorThermal)) {
					self.delayedTasks.forEach((task) => this.printTask(task));
					self.delayedTasks.clear();
				}
			},
			setTermo(value: string) {
				value && (self.errorThermal = false);
				self.printers.termo = value;
			},
			setOthers(value: string) {
				value && (self.errorOthers = false);
				self.printers.others = value;
			},
			setPrintPreview: flow(function* (task: PrintTask) {
				self.printPreview = PrintTask.create(task);
				let res = null;
				root.setLoading(true);

				if (self.printPreview.type === 'get-acceptance-for-individuals') {
					res = yield LabelService.getIndividualsGroupAcceptance([+self.printPreview.taskId], true).finally(
						closeLoader
					);
				} else if (self.printPreview.type === 'get-acceptance-for-legal-entities') {
					res = yield LabelService.getJuristicGroupAcceptance([+self.printPreview.taskId], true).finally(closeLoader);
				} else if (self.printPreview.type === 'refund-receipt') {
					res = yield LabelService.getRefundReceipt([+self.printPreview.taskId]).finally(closeLoader);
				} else if (self.printPreview.type === 'get-group-issuing-cash-on-delivery') {
					res = yield LabelService.getGroupCashOnDelivery([+self.printPreview.taskId]).finally(closeLoader);
				} else if (self.printPreview.type === 'get-issuance-for-individuals') {
					res = yield LabelService.getIndividualsGroupIssuance([+self.printPreview.taskId], true).finally(closeLoader);
				} else if (self.printPreview.type === 'cash-register-report-pdf') {
					self.printPreview.date &&
						(res = yield ReportsService.getReportOrdersPdf(self.printPreview.date).finally(closeLoader));
				} else if (self.printPreview.type === 'cash-register-report-pdf-employees-general') {
					self.printPreview.date &&
						(res = yield ReportsService.getReportPostalsPdf(self.printPreview.date, 2).finally(closeLoader));
				} else if (self.printPreview.type === 'cash-register-report-pdf-employees-full') {
					self.printPreview.date &&
						(res = yield ReportsService.getReportPostalsPdf(self.printPreview.date, 1).finally(closeLoader));
				} else if (self.printPreview.type === 'claim-act') {
					res = yield LabelService.getClaimAct(+self.printPreview.taskId).finally(closeLoader);
				} else if (self.printPreview.type === 'autopsy-act') {
					res = yield LabelService.getAutopsyAct(+self.printPreview.taskId).finally(closeLoader);
				} else if (self.printPreview.type === 'defective-condition-act') {
					res = yield LabelService.getDefectiveConditionAct(+self.printPreview.taskId).finally(closeLoader);
				} else if (self.printPreview.type === 'qr') {
					res = yield StoreService.getQrCode().finally(closeLoader);
				} else if (self.printPreview.type === 'thermal') {
					res = yield LabelService.getThermalAct(+self.printPreview.taskId).finally(closeLoader);
				} else if (self.printPreview.type === 'routing-lists') {
					res = yield LabelService.getRoutingListsAct(+self.printPreview.taskId).finally(closeLoader);
				} else if (task.type === 'routing-surplus') {
					res = yield LabelService.getRoutingSurplusAct(+self.printPreview.taskId).finally(closeLoader);
				} else {
					res = yield LabelService[self.printPreview.isMarketLabel ? 'getAddressMarketLabel' : 'getAddressLabel'](
						self.printPreview.taskId,
						task.type
					).finally(closeLoader);
				}

				saveGlobalBlob(self.printPreview.taskId, res?.data);
				self.pdfPreviewDataId = self.printPreview.taskId;
			}),
			cancelPrintPreview() {
				self.pdfPreviewDataId && deleteFromGlobalBlob(self.pdfPreviewDataId);
				self.printPreview = null;
				self.pdfPreviewDataId = null;
			},
			openPrinterSettings: flow(function* () {
				self.delayedTasks.forEach(({ type }) =>
					['thermal', 'static-space-qr'].includes(type)
						? !self.errorThermal && !self.printers.termo && (self.errorThermal = true)
						: !self.errorOthers && !self.printers.others && (self.errorOthers = true)
				);

				if (!self.printerSettingsOpen) {
					self.printerSettingsOpen = true;
					applySnapshot(self.tmpPrinters, toJS(self.printers));
					try {
						const res = yield PrintService.getPrinters();
						self.availablePrinters = yield res.json();
					} catch (e) {
						applySnapshot(self.availablePrinters, []);
					}
				}
			}),
			setErrorThermal(value: boolean) {
				self.errorThermal = value;
			},
			setErrorOthers(value: boolean) {
				self.errorOthers = value;
			},
			closePrinterSelect() {
				applySnapshot(self.printers, toJS(self.tmpPrinters));
				self.printerSettingsOpen = false;
				self.delayedTasks.clear();
			},
			printTask(printTask: PrintTask, noLoading?: boolean) {
				const noPrinters = !self.printers.termo && !self.printers.others;
				const noPrinterOfType = ['thermal', 'static-space-qr'].includes(printTask.type)
					? !self.printers.termo
					: !self.printers.others;

				if (noPrinters || noPrinterOfType) {
					self.delayedTasks.push(printTask);
					this.openPrinterSettings();
					return;
				}

				!noLoading && getParent<typeof RootStore>(self).setLoading(true);

				setTimeout(() => {
					const task = PrintTask.create(printTask);
					const close = !noLoading ? closeLoader : () => null;
					const callback = ({ data }: AxiosResponse): Promise<void> =>
						PrintService.print(
							data,
							['thermal', 'static-space-qr'].includes(task.type)
								? self.printers.termo || ''
								: self.printers.others || '',
							task.copies
						)
							.then(close)
							.catch(close);

					if (task.type === 'get-acceptance-for-legal-entities') {
						LabelService.getJuristicGroupAcceptance(task.ids).then(callback).catch(close);
					} else if (task.type === 'get-acceptance-for-individuals') {
						LabelService.getIndividualsGroupAcceptance(task.ids).then(callback).catch(close);
					} else if (task.type === 'get-group-issuing-cash-on-delivery') {
						//добавил условие т.к. засылался пустой массив
						LabelService.getGroupCashOnDelivery(task.id ? [+task.id] : task.ids)
							.then(callback)
							.catch((err) => {
								LogService.postLog({
									market_id: task.id,
									model_type: task.type,
									request: 'printTask: /api/ops/label/get-group-issuing-cash-on-delivery',
									response: JSON.stringify(err?.response || err || 'request: get-group-issuing-cash-on-delivery'),
								}).catch((err) => console.log('log', err));
								close();
							});
					} else if (task.type === 'get-issuance-for-individuals') {
						LabelService.getIndividualsGroupIssuance(task.ids)
							.then(callback)
							.catch((err) => {
								LogService.postLog({
									market_id: task.id,
									model_type: task.type,
									request: 'printTask: /api/ops/label/get-issuance-for-individuals',
									response: JSON.stringify(err?.response || err || 'request: get-issuance-for-individuals'),
								}).catch((err) => console.log('log', err));
								close();
							});
					} else if (task.type === 'refund-act') {
						task.id && LabelService.getRefundAct(task.id).then(callback).catch(close);
					} else if (task.type === 'refund-receipt') {
						task.ids?.length && LabelService.getRefundReceipt(task.ids).then(callback).catch(close);
					} else if (task.type === 'payment-act') {
						task.id && LabelService.getPaymentAct(task.id).then(callback).catch(close);
					} else if (task.type === 'cash-register-report-pdf') {
						task.date && ReportsService.getReportOrdersPdf(task.date).then(callback).catch(close);
					} else if (task.type === 'cash-register-report-pdf-employees-general') {
						task.date && ReportsService.getReportPostalsPdf(task.date, 2).then(callback).catch(close);
					} else if (task.type === 'cash-register-report-pdf-employees-full') {
						task.date && ReportsService.getReportPostalsPdf(task.date, 1).then(callback).catch(close);
					} else if (task.type === 'claim-act') {
						task.id && LabelService.getClaimAct(task.id).then(callback).catch(close);
					} else if (task.type === 'autopsy-act') {
						task.id && LabelService.getAutopsyAct(task.id).then(callback).catch(close);
					} else if (task.type === 'defective-condition-act') {
						task.id && LabelService.getDefectiveConditionAct(task.id).then(callback).catch(close);
					} else if (task.type === 'inventory-act') {
						task.id && LabelService.getInventoryAct(task.id).then(callback).catch(close);
					} else if (task.type === 'qr') {
						StoreService.getQrCode().then(callback).catch(close);
					} else if (task.type === 'thermal') {
						task.id && LabelService.getThermalAct(task.id).then(callback).catch(close);
					} else if (task.type === 'routing-lists') {
						task.id && LabelService.getRoutingListsAct(task.id).then(callback).catch(close);
					} else if (task.type === 'routing-surplus') {
						task.id && LabelService.getRoutingSurplusAct(task.id).then(callback).catch(close);
					} else if (task.type === 'static-space-qr') {
						task.id && LabelService.getStaticSpaceQr(task.id).then(callback).catch(close);
					} else if (task.type === 'break') {
						task.id && LabelService.getBreakInfo(task.id).then(callback).catch(close);
					} else {
						LabelService[task.isMarketLabel ? 'getAddressMarketLabel' : 'getAddressLabel'](task.taskId, task.type)
							.then(callback)
							.catch(close);
					}
					this.cancelPrintPreview();
				}, 100);
			},
			printReports() {
				const date = `${addZero(dayjs().year())}${addZero(dayjs().month() + 1)}${addZero(dayjs().date())}`;
				this.printTask({ date, id: +date || null, type: 'cash-register-report-pdf-employees-general' });
				this.printTask({ date, id: +date || null, type: 'cash-register-report-pdf' });
			},
		};
	})
	.views((self) => ({
		get printersSelected() {
			return !!self.printers.others || !!self.printers.termo;
		},
		get termo() {
			return self.printers.termo;
		},
		get others() {
			return self.printers.others;
		},
		get openPrinterSelect() {
			return self.printerSettingsOpen;
		},
		get allowSavePrinters() {
			return self.tmpPrinters.termo || self.tmpPrinters.others;
		},
		get printersError() {
			return self.errorOthers || self.errorThermal;
		},
	}));

export default Print;

export interface PrintTask extends SnapshotIn<typeof PrintTask> {}
