import Button from '@components/buttons/button';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FC, useEffect, useRef } from 'react';

import ConfirmModal from './components/confirm-modal';
import Header from './components/header';
import Table from './components/table';
import styles from './index.module.scss';

const TableGoodsModal: FC = () => {
	const submitRef = useRef<HTMLElement | null>(null);
	const {
		scanner,
		shipment: {
			post: { consolidation },
		},
		setLoading,
	} = useStore();

	const { modalGoodsTable: data } = consolidation;

	const submit = (): void => {
		submitRef.current?.blur();
		data?.submitSpace();
	};

	useEffect(() => {
		if (data) {
			setLoading(false);
			scanner.addHandler(data?.scanBarcode);
		}

		return scanner.removeHandler;
	}, [!!data]);

	return (
		<>
			<Modal
				title={
					<div>
						<h3 className={styles.title}>{data?.space?.type_name || data?.space?.name || 'Мешок'}</h3>
						{data?.space?.isTrouble ? (
							<p className={cn(styles.description, styles.description__trouble)}>
								Обнаружены посылки в неверном статусе, привязка невозможна.
								<br />
								Отсканируйте посылки из списка ниже повторно и проверьте раздел &lsquo;Приёмка - Проблемные
								посылки&rsquo;.
							</p>
						) : (
							<p className={styles.description}>
								{data?.isStatic
									? 'Отсканируйте отправления, которые нельзя разместить в мешок'
									: 'Отсканируйте почтовые отправления'}
							</p>
						)}
					</div>
				}
				onCancel={data?.close}
				open={!!data}
				width={880}
				className={styles.body}
				classNameModal={styles.modal}
				centered
				destroyOnClose
				okButtonProps={{ disabled: !data?.space?.hasScanned }}
				footer={
					<div className={styles.footer}>
						<Button onClick={data?.close}>Закрыть</Button>
						<Button type="primary" onClick={submit} ref={submitRef}>
							{data?.space?.isTrouble ? 'Подтвердить' : data?.isStatic ? 'Передать' : 'Привязать пломбу'}
						</Button>
					</div>
				}
			>
				{!data?.space?.isTrouble && <Header />}
				<OverlayScrollbarsComponent className={styles.scroll} options={{ scrollbars: { autoHide: 'move' } }}>
					<Table />
				</OverlayScrollbarsComponent>
			</Modal>
			<ConfirmModal />
		</>
	);
};

export default observer(TableGoodsModal);
