/* eslint-disable @typescript-eslint/no-empty-interface */
import { types } from 'mobx-state-tree';

const { model } = types;

const Scanner = model('scanner', {
	value: '',
}).actions((self) => {
	let action: ((number: string) => void) | null = null;
	let timerId: any;

	return {
		clear() {
			self.value = '';
		},
		handleScanner({ key }: KeyboardEvent) {
			clearTimeout(timerId);
			timerId = setTimeout(this.clear, 99);

			if (key.length === 1) {
				self.value += key;
			}

			if (key === 'Enter' && self.value.length > 5) {
				action && action(self.value);
				this.clear();
			}
		},
		addHandler(func: (number: string) => void) {
			action = func;
			document.addEventListener('keyup', this.handleScanner);
		},
		removeHandler() {
			action = null;
			document.removeEventListener('keyup', this.handleScanner);
		},
	};
});

export default Scanner;
