import { types } from 'mobx-state-tree';

import ConsolidationModel from './consolidation';
import ParcelsModel from './parcels';

const { model, union, literal, optional } = types;

const Post = model('post', {
	tab: optional(union(literal('consolidation'), literal('parcels')), 'consolidation'),
	parcels: ParcelsModel,
	consolidation: ConsolidationModel,
}).actions((self) => {
	return {
		setTab(tab: 'consolidation' | 'parcels') {
			self.tab = tab;
		},
	};
});

export default Post;
